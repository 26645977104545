<div class="summary-order-footer-container">
    <button mat-button mat-flat-button class="secondary" (click)="irInicio()" *ngIf="inWorflow">
        {{ buttonSecondaryText | translate:locale.language }}
    </button>
    <button mat-button mat-flat-button class="primary" (click)="verOrdenes()" *ngIf="inWorflow">
        <span> {{ buttonPrimaryText | translate:locale.language }} </span>
    </button>
    <app-summary-order-download-button
        *ngIf="!inWorflow"
        [atenciones]="atenciones"
        [comentarios]="comentarios"
        [diagnosticos]="diagnosticos"
        [firmaDoctor]="firmaDoctor"
        [historia]="historia"
    ></app-summary-order-download-button>
</div>