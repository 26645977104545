import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBaseService } from './http-base-service';

@Injectable({
  providedIn: 'root'
})
export class PrintsService {

    private url = 'api/impresiones';

  constructor(
    private http: HttpBaseService
  ) { }

  getPdf(args: MedicalOrderReport):Observable<any> {
    return this.http.post<MedicalOrderFile>(`${this.url}/pdf`, args);
  }

}

export interface MedicalOrderFile {
  fileName: string;
  pdfBase64: string;
}

export interface MedicalOrderReport {
    valorLocalizacion: string;
    idAreaSistema: number;
    idAreaSistemaImpresion: number;
    idEmpresa: number;
    idPersona: number;
    idResponsableImpresion: number;
    urlLogoEmpresa: string;
    htmlReporte: string;
    formatoHoja: FormatoHojaReporte;
    idPaciente: number;
    idResponsableServicio: number;
    idContactoClinico: number;
    urlFirma: string;
    idLocalidad: number;
    fechaOrdenMedica: Date;
    comentario: string;
    diagnosticos: string;
    numeroOrden: string;
    categorias: ReporteCategoriaConceptoDTO[];   
}

export class ReporteCategoriaConceptoDTO
{
    nombre?: string;
    conceptos?: ReporteCategoriaConceptoDTO[];
    comentario?: string;;
}

export enum FormatoHojaReporte
{
    CARTA,
    PERSONALIZADO
}
