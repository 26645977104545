import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MedicalOrderService } from 'src/app/core/services/medical-order.service';
import { CloseRouteDialogMessage, ComponentFinishLoadingMessage, MessageBusService, NavigationBackMessage } from 'src/app/core/services/message-bus.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { ComponentName } from 'src/app/shared/enums/component-name.enum';
import { CONCEPTOS_ID, MEDICAL_REQUEST_TYPE, TIPO_CONCEPTOS_ID, TITLE_MAPPING } from 'src/app/shared/enums/medical-request-type.enum';
import { AttentionsHistory } from 'src/app/shared/interfaces/attentions-history';
import { ClinicalHistoryItem } from 'src/app/shared/interfaces/clinical-history-item';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { PublicProfile } from 'src/app/shared/models/people/public-profile.model';
import { PatientVerificationComponentData, PatientVerificationDataModel } from 'src/app/shared/models/workflow/models/patient-verification-data.model';
import { RouteDialogType } from '../../dialogs/route-dialog/route-dialog.component';
import { MedicalOrder } from 'src/app/shared/interfaces/medical-order';
import { Location } from 'src/app/shared/models/people/location.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { BaseService } from 'src/app/core/services/base.service';
import { PatientPartial } from 'src/app/shared/interfaces/patient-partial';
import { ClinicContact } from 'src/app/shared/interfaces/clinic-contact';
import { RequestsToVerifyConfirmationResponse, RequestsToVerifyService } from 'src/app/core/services/requests-to-verify.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-summary-medical-order',
  templateUrl: './summary-medical-order.component.html',
  styleUrls: ['./summary-medical-order.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup(ComponentName.SUMMARY_MEDICAL_ORDER)
export class SummaryMedicalOrderComponent implements IControlComponent, OnInit {

  @Input() inWorflow: boolean = true;
  atenciones: ClinicalHistoryItem[] = [
    { nombreConcepto: '', items: [ { nombreConcepto: '' }, { nombreConcepto: '' }, { nombreConcepto: '' }, { nombreConcepto: '' }, { nombreConcepto: '' } ] },
    { nombreConcepto: '', items: [ { nombreConcepto: '' }, { nombreConcepto: '' }, { nombreConcepto: '' } ] }
  ];
  componentData: PatientVerificationComponentData;
  comentarios?: ClinicalHistoryItem;
  diagnosticos = '';
  diagnosticosLabel = 'diagnosis';
  data: any;
  initialLoading = true;
  loading = false;
  model = new  PatientVerificationDataModel();
  isAndroid = false;
  history: AttentionsHistory = {
    solicitud: { fechaInicio: '1990-04-02', idResponsableServicio: 0, idPersonaEmisora: 0, idTipoSolicitud: 29 },
    historialAtencion: { atenciones: []},
    ubicacionFotoLogoTitulo: '',
    empresaPortal: { denominacion: 'N/A', numeroTelParticular:'N/A', numeroTelCelular: 'N/A', direccionLaboral: 'N/A' }
  };
  dateString = 'N/A';
  language: string;
  localidad: Location;
  patient: PatientPartial;
  publicProfile?: PublicProfile | null;
  title = 'N/A';
  requestTypeName = 'N/A';
  requestNumber = 'N/A';
  buttonSecondaryText = 'shared.medicalOrder.summary.buttonText2';
  buttonPrimaryText = 'shared.medicalOrder.summary.buttonTextPrescriptions';
  private ngUnsubscribe = new Subject();
  idPaciente: number;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private userService: UserService,
    private baseService: BaseService,
    private messageBusService: MessageBusService,
    private medicalOrderSevice: MedicalOrderService,
    private requestsToVerifyService: RequestsToVerifyService,
    private publicProfileService: PublicProfileService,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.language = locale.dateLanguage ?? 'es-AR';
    this.messageBusService.onNavigateBack().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onNavigateBackNext.bind(this));
    this.breakpointObserver.observe(Breakpoints.Handset).pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.mobileNext.bind(this));
  }

  private onNavigateBackNext(x: NavigationBackMessage) {
    if (x.dialogId != RouteDialogType.MY_ORDER_SUMMARY) return;
    this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
  }

  private mobileNext(x: BreakpointState) {
    if (!x.matches) return;
    this.buttonSecondaryText = 'shared.medicalOrder.summary.buttonTextMobile2';
  }

  ngOnInit(): void {
    const idVerificacion: string = this.route.snapshot.params.idVerificacion;
    if (this.inWorflow) {
      this.requestsToVerifyService.confirmation(idVerificacion).subscribe((confirmModel:RequestsToVerifyConfirmationResponse) => {

        this.idPaciente = confirmModel.idPaciente;

        this.medicalOrderSevice.get(idVerificacion).subscribe({ next: this.processAttentionsHistory.bind(this) });
      });
    } else {
      this.medicalOrderSevice.getById(idVerificacion)
        .pipe(map(this.mapMedicalOrderIntoHistory))
        .subscribe(this.processAttentionsHistory.bind(this));
    }
  }

  private mapMedicalOrderIntoHistory(order: MedicalOrder): AttentionsHistory {
    const result: AttentionsHistory = {
      empresaPortal: order.empresaPortal,
      historialAtencion: {
        atenciones: order.detalles,
        contactoClinico: {idPaciente: order.idPacientePhr} as ClinicContact,
      },
      solicitud: {
        fechaInicio: order.fecha,
        idEmpresa: order.idEmpresaPortal,
        idPersonaEmisora: order.idPacientePhr,
        idResponsableServicio: order.idResponsableDoctor,
        idTipoSolicitud: order.idTipoSolicitud,
        numeroSolicitud: order.numeroSolicitud
      },
      ubicacionFotoLogoTitulo: order.ubicacionFotoLogoTitulo
    };
    return result;
  }

  private processAttentionsHistory(args: AttentionsHistory): void {
    this.history = args;
    this.setTitle(this.history?.solicitud.idTipoSolicitud, this.history?.solicitud.numeroSolicitud);
    this.setDate(this.history?.solicitud.fechaInicio);
    this.parseControlData();
    this.processHistorialAtencion(args);

    if (args.historialAtencion.contactoClinico)
      this.patientService.getPartial(args.historialAtencion.contactoClinico.idPaciente).subscribe({ next: args => this.patient = args });
  
    this.publicProfileService.getPublicProfileByIds([this.history.solicitud.idResponsableServicio]) 
      .subscribe({ next: this.getPublicProfileByIdNext.bind(this) });
    this.sendComponentFinishLoadingMessage();
    setTimeout(() => this.initialLoading = false, 500);
  }

  private setTitle(idTipoSolicitud = 0, numeroSolicitud = 0): void {
    const currentRequestTypeName = TITLE_MAPPING[idTipoSolicitud] || '';

    this.requestNumber = this.medicalOrderSevice.buildRequestNumber(idTipoSolicitud, numeroSolicitud);
    this.requestTypeName = currentRequestTypeName;
    this.title = `${currentRequestTypeName}Short`;
  }

  private setDate(args?: string): void {
    if (!args) return;
    this.dateString = this.baseService.getBasicDateFormat(new Date(args), true);
  }

  private parseControlData(): void {
    this.loading = true;

    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as PatientVerificationDataModel;

      if(this.model.idPaciente > 0){
        this.idPaciente = this.model.idPaciente;
      }

      this.componentData = this.model.configData;
    }

    this.loading = false;
  }

  private processHistorialAtencion(args: AttentionsHistory) {
    this.atenciones = this.filterAtenciones(args.historialAtencion.atenciones);
    this.atenciones.forEach(x => this.setItems(x, args));
    this.comentarios = args.historialAtencion.atenciones.find(x => x.idConcepto == CONCEPTOS_ID.COMENTARIO);
    this.diagnosticos = this.getDiagnosis(args.historialAtencion.atenciones);
  }

  private filterAtenciones(atenciones: ClinicalHistoryItem[]): ClinicalHistoryItem[] {
    return atenciones.filter(x => !x.idConceptoObjeto && x.idConcepto != CONCEPTOS_ID.COMENTARIO && x.idTipoConcepto != TIPO_CONCEPTOS_ID.DIAGNOSTICO);
  }

  private getDiagnosis(atenciones: ClinicalHistoryItem[]): string {
    const diagnosis = atenciones
      .filter(x => x.idTipoConcepto == TIPO_CONCEPTOS_ID.DIAGNOSTICO)
      .map(x => x.valorCodigoTerminoReferenciaConceptoClinico || this.truncate(x.nombreConcepto));
    let result = diagnosis.join(', ');

    if (diagnosis.length > 1) {
      const lastItem = diagnosis.pop();
      result = `${diagnosis.join(', ')} y ${lastItem}`;
      this.diagnosticosLabel = 'diagnoses';
    }

    return result;
  }

  private truncate(text: string, maxLength = 28): string {
    let result = text;
    if (text.length > maxLength) {
      result = `${text.substring(0, maxLength)}...`
    }
    return result;
  }

  private setItems(x: ClinicalHistoryItem, args: AttentionsHistory): void {
    x.items = args.historialAtencion.atenciones.filter(y => y.idConceptoObjeto == x.idConcepto);
  }

  private sendComponentFinishLoadingMessage(): void {
    if (!this.data) return;

    const event = new ComponentFinishLoadingMessage();
    
    event.idFormulario = this.data.idFormulario;
    event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }

  private getPublicProfileByIdNext(args: (PublicProfile | null)[]): void {
    this.publicProfile = args[0];
  }

  showNumbers(): boolean {
    return MEDICAL_REQUEST_TYPE.LABORATORY_ORDER == this.history?.solicitud?.idTipoSolicitud;
  }

}
