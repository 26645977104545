import { Component, HostBinding, Inject, Input } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { MedicalOrderService } from 'src/app/core/services/medical-order.service';
import { FormatoHojaReporte, MedicalOrderFile, MedicalOrderReport, PrintsService, ReporteCategoriaConceptoDTO } from 'src/app/core/services/prints.service';
import { MEDICAL_REQUEST_TYPE, MY_MEDICAL_ORDER_PRINT_SYSTEM_AREA } from 'src/app/shared/enums/medical-request-type.enum';
import { AttentionsHistory } from 'src/app/shared/interfaces/attentions-history';
import { ClinicalHistoryItem } from 'src/app/shared/interfaces/clinical-history-item';

@Component({
  selector: 'app-summary-order-download-button',
  templateUrl: './summary-order-download-button.component.html',
  styleUrls: ['./summary-order-download-button.component.css']
})
export class SummaryOrderDownloadButtonComponent {

  @HostBinding('style.flex-grow') flexGrow = '1';
  @HostBinding('style.max-width') maxWidth = '464px';
  @Input() atenciones: ClinicalHistoryItem[] = [];
  @Input() comentarios?: ClinicalHistoryItem;
  @Input() diagnosticos = '';
  @Input() firmaDoctor?: string | null;
  @Input() historia: AttentionsHistory;

  disabled = false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private medicalOrderService: MedicalOrderService,
    private printsService: PrintsService,
  ) { }

  obtenerReporte(): void {
    this.disabled = true;

    const body: MedicalOrderReport = {
      valorLocalizacion: 'es',
      idAreaSistema: this.historia.solicitud.idAreaSistema ?? -1,
      idAreaSistemaImpresion: MY_MEDICAL_ORDER_PRINT_SYSTEM_AREA[this.historia.solicitud.idTipoSolicitud ?? -1] ,
      idEmpresa: this.historia.solicitud?.idEmpresa ?? -1,
      idPersona: this.historia.solicitud?.idPersonaEmisora ?? -1,
      idResponsableImpresion: this.historia.solicitud?.idResponsableServicio ?? -1,
      urlLogoEmpresa: this.historia.ubicacionFotoLogoTitulo,
      htmlReporte: '',
      formatoHoja: FormatoHojaReporte.CARTA,
      idPaciente: this.historia.historialAtencion.contactoClinico?.idPaciente ?? -1,
      idResponsableServicio: this.historia.solicitud?.idResponsableServicio ?? -1,
      idContactoClinico: this.historia.historialAtencion.contactoClinico?.id ?? -1,
      urlFirma: this.firmaDoctor ?? '',
      idLocalidad: -1,
      fechaOrdenMedica: new Date(),
      comentario: this.comentarios?.valorConcepto ?? '',
      diagnosticos: this.diagnosticos,
      numeroOrden: this.medicalOrderService.buildRequestNumber(this.historia.solicitud.idTipoSolicitud, this.historia.solicitud.numeroSolicitud),
      categorias: this.obtenerCategoriasPorTiposolicitud(this.historia.solicitud.idTipoSolicitud ?? -1, this.atenciones)
    };

    this.printsService.getPdf(body).subscribe(this.procesarPdf.bind(this));
  }

  private obtenerCategoriasPorTiposolicitud(idTipoSolicitud: number, conceptos: ClinicalHistoryItem[]): ReporteCategoriaConceptoDTO[] {
    if (idTipoSolicitud == MEDICAL_REQUEST_TYPE.MEDICAL_PRESCRIPTION) {
      return [{nombre: "Otros", conceptos: this.obtenerCategoriasPorTiposolicitud(-1, conceptos)}]
    }
    return conceptos.map(this.mapearConceptos.bind(this));
  }

  private mapearConceptos(concepto: ClinicalHistoryItem): ReporteCategoriaConceptoDTO {
    return {
      conceptos: concepto.items?.map(this.mapearConceptos.bind(this)),
      comentario: concepto.valorConcepto,
      nombre: concepto.nombreConcepto
    }
  }

  private procesarPdf(response: MedicalOrderFile): void {
    const byteCharacters = atob(response.pdfBase64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);

    this.download(blobUrl, response.fileName);

    this.disabled = false;
  }

  private download(blobUrl: string, filename: string): void {
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}
